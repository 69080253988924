//export const ip = "http://52.13.29.22:";
console.log("====================== " + process.env.NODE_ENV);
let ip_var;
let port_var;
if (process.env.NODE_ENV === "development") {
  ip_var = "http://localhost:";
  port_var = { hive: "4000", hermes: "3001" };

} else if (process.env.NODE_ENV === "production") {
  ip_var = "https://hive-lobz.onrender.com:";
  port_var = { hive: "443", hermes: "443" };

} else {
  console.log("this condition should not execute in development or production");
  ip_var = "http://localhost:";
}
export const ip = ip_var;
console.log("==================== " + ip + " ==============");
// export const port = { hive: "4000", hermes: "3001" };
export const port = port_var;
