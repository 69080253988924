import React, { useEffect, useState } from "react";
import auth from "../Auth/auth";
import { GoogleLogin } from "@react-oauth/google";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

function GoogleLoginButton({ history }) {
  const navigate = useNavigate();
  const [tokenId, setTokenId] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    async function checkTokenInCookie() {
      if (tokenId) {
        cookies.set("tokenId", tokenId);
        console.log(cookies.get("tokenId"));
      } else {
        // Push user to login page
        console.log("Got here after refresh");
        //history.push("/");
      }
    }

    async function componentDidMount() {
      //console.log("component mounted", this.props);
      let result = await auth.server_google_authetication(tokenId);
      //console.log("isnide compnet did mount" + JSON.stringify(result));
      if (result) {
        console.log(
          "inside componentDidMount: token found in cookie: verified with server: navigating to dashboard"
        );
        auth.login(() => {
          navigate("/dashboard");
        });
      }
    }

    checkTokenInCookie();
    componentDidMount();
  }, [tokenId, history]);

  const responseGoogleSuccess = async (response) => {
    console.log("google response success" + JSON.stringify(response));
    const credentailResponseDecoded = jwt_decode(response.credential);
    console.log(credentailResponseDecoded);
    const user_data = {
      name: credentailResponseDecoded.name,
      email: credentailResponseDecoded.email,
      picture: credentailResponseDecoded.picture,
    };
    setTokenId(response.credential);
    setUserData(user_data);
    console.log("user_data === " + JSON.stringify(user_data));

    if (tokenId) {
      cookies.set("tokenId", tokenId);
      console.log(cookies.get("tokenId"));
    } else {
      // Push user to login page
      console.log("Got here after refresh");
      //history.push("/");
    }

    const result = await auth.oneTime_FirstLogin(response.credential);
    console.log("printing result here");
    console.log(result);
    if (result.data.tokenIsVerified) {
      auth.set_user_data(user_data);
      auth.login(() => {
        navigate("/dashboard");
      });
    } else {
      auth.logout(() => {
        navigate("/");
      });
    }
  };

  const responseGoogleFailure = (response) => {
    console.log("google response failure = " + JSON.stringify(response));
  };

  return (
    <div>
      <GoogleLogin
        buttonText="Login with Google"
        onSuccess={responseGoogleSuccess}
        onError={responseGoogleFailure}
      />
    </div>
  );
}

export default GoogleLoginButton;
