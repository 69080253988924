import React, { Component } from "react";
import auth from "../Auth/auth";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import { Button, TextField, Typography, Stack } from "@mui/material";

export class CreateTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenId: null,
      user_data: {},
      TeamName: "",
      response: "",
    };
  }
  async componentDidMount() {
    console.log("component mounted : create team component");
    const user_dataa = await auth.get_user_data();
    console.log("user_data inside dashboard=== " + JSON.stringify(user_dataa));
    this.setState({
      tokenId: this.state.tokenId,
      user_data: user_dataa,
    });
    console.log(
      "welcome : Email : " +
        this.state.user_data.email +
        " : Name : " +
        this.state.user_data.name
    );
  }

  handleChange = (event) => {
    this.setState({ TeamName: event.target.value });
    console.log("change", this.state.TeamName);
  };

  handleSubmit = async () => {
    console.log(this.state.TeamName);
    console.log(this.state.user_data.email);
    let obj = {
      email: this.state.user_data.email,
      TeamName: this.state.TeamName,
    };
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint = "/create_team";
    let res = await Photon.post(ip, port, endpoint, obj);
    console.log(JSON.stringify(res));
    this.setState({ response: res.data });
    this.setState({ TeamName: "" });
  };

  keyPress(e) {
    if (e.keyCode == 13) {
      console.log("value", e.target.value);
      // put the login here
      //this.handleSubmit();
    }
  }
  render() {
    return (
      <div>
        <Stack>
          <Stack width={300}>
            <Typography variant="h5" sx={{ py: 1, mx: 2 }}>
              Create Team
            </Typography>
            <TextField
              label="Enter Team Name"
              variant="outlined"
              value={this.state.TeamName}
              onChange={this.handleChange}
              onKeyDown={this.keyPress}
              sx={{ py: 1, mx: 2 }}
            />
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              sx={{ py: 1, mx: 2 }}
            >
              Create Team
            </Button>
          </Stack>
          <Stack>
            {this.state.response.message}
            Give this code to your Team Members {this.state.response.join_code}
          </Stack>
        </Stack>
      </div>
    );
  }
}

export default CreateTeam;
