import React from "react";

function HowItWorks() {
  return (
    <section id="how-it-work" className="how-it-work">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="how-it-work-content">
              <div className="title">
                <h3>
                  ZenithCode: Learn Internet Computer Protocol (ICP) and Motoko
                </h3>
              </div>
              <div className="works-body">
                ZenithCode is a learning platform designed to help developers
                master the Internet Computer Protocol (ICP) and its primary
                language, Motoko. Through a series of coding challenges, users
                can build their skills by working on progressively more complex
                tasks. Currently available in a free preview, ZenithCode makes
                learning Web3 development accessible and engaging.
                <div className="work-item">
                  <br />
                  <div className="work-title">
                    {/* <span>1</span> */}
                    <h4>What is Internet Computer Protocol (ICP)?</h4>
                  </div>
                  <div className="work-title">
                    {/* <div className="work-content"> */}
                    <p>
                      <ul>
                        <li>
                          <span>1</span>A decentralized blockchain that enables
                          the creation of scalable, fast, and secure
                          decentralized applications (dApps) without centralized
                          servers.
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <span>2</span>
                          ICP uses canister smart contracts that run backend and
                          frontend code entirely on-chain, offering full
                          decentralization.
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <span>3</span>
                          It eliminates the need for traditional cloud services
                          like AWS, making it highly secure and
                          censorship-resistant.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                {/* work-item */}
                <div className="work-item">
                  <div className="work-title">
                    <h4>How ZenithCode Helps You Learn ICP</h4>
                  </div>
                  <div className="work-title">
                    <ul>
                      <li>
                        <span>1</span>
                        Provides hands-on coding challenges specifically
                        designed for ICP and Motoko, gradually increasing in
                        complexity.
                      </li>
                      <br />
                      <li>
                        <span>2</span>
                        Offers practical experience with building decentralized
                        applications and smart contracts using ICP's canisters.
                      </li>
                      <br />
                      <li>
                        <span>3</span>
                        Helps you master the key features of ICP, such as
                        decentralized storage, blockchain governance, and
                        seamless scalability.
                      </li>
                      <br />
                    </ul>
                  </div>
                </div>
                {/* work-item */}
                {/* <div className="work-item">
                  <div className="work-title">
                    <h3>Mock Interview</h3>
                  </div>
                  <div className="work-title">
                    <ul>
                      <li>
                        <span>1</span>
                        You will have access to mock interviews which simulate
                        real life FAANG+ interviews and will be conducted by Ex
                        FAANG Software Engineers.
                      </li>
                      <br />
                      <li>
                        <span>2</span>
                        You will get detailed feedback on your interview
                        performance after your mock interview.
                      </li>
                      <br />
                      <li>
                        <span>3</span>
                        We will help you with building resume which will help
                        you land tech interviews at FAANG+ companies.
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="work-item">
                  <div className="work-title">
                    <h3>JOB OFFER</h3>
                  </div>
                  <div className="work-title">
                    <ul>
                      <li>
                        <span>1</span>
                        We will support you through the entire interview and job
                        offer process, including salary negotiation, until you
                        secure a fantastic job offer.
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* work-item */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
