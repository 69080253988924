import React from 'react';
import GoogleLoginButton from "../google_login_button";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function Header(props) {
  return (
    <Stack direction="row" justifyContent={'space-between'} sx={{ px: 5}}>
      <Stack direction="row" sx={{my:2}}>
        <Typography variant="h5">
          Zenith Code
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ p: 1}}>
        <a className="p-2 text-dark" href="#">Home</a>
        <a className="p-2 text-dark" href="#how-it-work">How it works!</a>
        <a className="p-2 text-dark" href="#pricing">Pricing</a>
        <GoogleLoginButton history={props.history} />
      </Stack>
     </Stack>
  );
}

export default Header;
