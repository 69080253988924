import React, { useEffect, useState } from "react";
import Header from "../header";
import QuestionBox from "./question_box";
import { useParams } from "react-router-dom";

function AdminPage(props) {
  const [questionData, setQuestionData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const questionObj = {
      id: id,
    };

    setQuestionData(questionObj);
    console.log(questionData);
  }, []);

  return (
    <div>
      <Header history={props.history} />

      <div className="flexbox-container">
        <QuestionBox question_data={questionData} />
      </div>
    </div>
  );
}

export default AdminPage;

// import React, { Component } from "react";
// import auth from "../Auth/auth";
// import Cookies from "universal-cookie";
// import Header from "../header";
// import QuestionBox from "./question_box";

// import "../../css/style.css";

// const cookies = new Cookies();
// export class AdminPage extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       tokenId: null,
//       question_data: {},
//     };
//   }
//   async componentDidMount() {
//     console.log("component mounted : AdminPage", this.props);

//     //check if admin otherwise push to dashboard page
//     /*let ip = Constants.ip;
//     let port = Constants.port.hive;
//     let endpoint = "/get_question_by_id";
//     let obj = { id: question_id };

//     let res = await Photon.post(ip, port, endpoint, obj);*/
//     console.log(this.props.match.params);
//     const questionObj = {
//       id: this.props.match.params.id,
//       name: this.props.match.params.name,
//       deatil: "",
//     };
//     await this.setState({
//       question_data: questionObj,
//     });
//     console.log(this.state.question_data);
//   }
//   changeToAdminPage = () => {
//     console.log("change page to admin panel");
//     this.props.history.push("/adminpanel");
//   };

//   render() {
//     return (
//       <div>
//         <Header history={this.props.history} />
//         <a onClick={this.changeToAdminPage}>Admin</a>

//         <div className="flexbox-container">
//           <QuestionBox question_data={this.state.question_data} />
//         </div>
//       </div>
//     );
//   }
// }

// export default AdminPage;
