import React from "react";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./home/groovyWalk.json";
import Dfinity from "../dfinity.png";

function HeroSection() {
  return (
    <section className="hero-area gap" id="hello">
      <div className="container">
        <div className="row">
          <div className="col-half">
            <div className="hero-content">
              <h2>
                Learn ICP <img src={Dfinity} width={100} height={100} />
              </h2>
              <p>Learn ICP by practicing coding questions in online editor</p>
              {/* Uncomment the following lines if you want to include the login button */}
              {/* <div className="login-btn">
                <a href="#">
                  <img src="assets/images/signin-btn-bg.png" alt="Login Button" />
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-half">
            <div className="hero-img">
              <Lottie animationData={groovyWalkAnimation} loop={true} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
