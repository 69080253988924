import React, { useEffect } from "react";
import styles from "../../index.css";

import './LandingPageHelper/home/assets/fonts/fonts.css'; // Import font styles
import './LandingPageHelper/home/assets/css/all.min.css'; // Import all.min.css
import './LandingPageHelper/home/assets/css/default.css'; // Import default.css
import './LandingPageHelper/home/style.css'; // Import your style.css
import './LandingPageHelper/home/assets/css/responsive.css'; // Import responsive.css
import './LandingPageHelper/home/bootstrap.min.css'; // Import Bootstrap CSS
import './LandingPageHelper/home/pricing.css'; // Import pricing.css

import Header from './LandingPageHelper/header';
import HeroSection from './LandingPageHelper/herosection';
import HowItWorks from './LandingPageHelper/howItWorks';
import Pricing from './LandingPageHelper/pricing';

function LandingPage(props) {
  useEffect(() => {
    console.log("component mounted : LandingPage", props);
  }, []);

  return (
    <div>
        <Header history={props.history}/>
        <HeroSection />
        <HowItWorks />
        <Pricing />
    </div>
  );
}

export default LandingPage;
