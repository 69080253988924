import React, { useState, useEffect } from "react";
import auth from "../Auth/auth";
import Header from "../header";
import { Box, Stack, Paper, Avatar, Typography } from "@mui/material";

function ProfilePage(props) {
  const [userData, setUserData] = useState({});
  useEffect(() => setUserData(auth.get_user_data()), []);

  return (
    <Box>
      <Header history={props.history} />
      <Stack direction="row" justifyContent="center" margin={20} border={0}>
        <Paper elevation={3} sx={{ width: 700, height: 300 }}>
          <Stack direction="row" justifyContent="space-between" margin={10}>
            <Stack justifyContent="center">
              <Avatar
                alt={userData.name}
                src={userData.picture}
                sx={{ width: 150, height: 150 }}
              />
            </Stack>
            <Stack justifyContent="center">
              <Typography variant="h3">{userData.name}</Typography>
              <Typography variant="h5">{userData.email}</Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
}

export default ProfilePage;
