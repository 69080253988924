import React, { useState, useEffect } from "react";
import auth from "../Auth/auth";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import base64 from "react-native-base64";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

// import "antd/dist/antd.css";

import CodeBlock from "./CodeBlock";

const { TabPane } = Tabs;

function QuestionBox(props) {
  const [questionName, setQuestionName] = useState();
  const [questionData, setQuestionData] = useState({});
  const [showSolution, setShowSolution] = useState({});
  const { ip, port } = Constants;

  const navigate = useNavigate();

  const fetchQuestionFnx = async (question_id, endpoint) => {

    let decodedQuestion;
    let decodedSolution;

    let res = await Photon.post(ip, port.hive, endpoint, { id: question_id });
    
    if ("question" in res.data) {
      console.log("got inside 123");
      decodedQuestion = base64.decode(res.data.question);
      setQuestionData(decodedQuestion);
    } else {
      // Move to dashboard
      console.log('got inside else');
      //navigate("/dashboard");
    }

    if ("solution" in res.data) {
      decodedSolution = base64.decode(res.data.solution);
      setShowSolution(decodedSolution);

    } 
    
  };

  useEffect(() => {
    console.log(
      "This is id question == " + JSON.stringify(props.question_data)
    );
    if (props && props.question_data){
      fetchQuestionFnx(props.question_data.id, "/get_question_by_id");
      fetchQuestionFnx(props.question_data.id, "/get_solution_by_id");
      setQuestionName(props.question_data.name);
    }
    
  }, [props]);

  const callback = (key) => {
    console.log(key);
    console.log(showSolution);
  };

  return (
      <Paper elevation={3} sx={{p: 2  , width: "23%"}}>
       <Typography variant="h5"
        >
        {questionName}
       </Typography>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Question" key="1">
            <ReactMarkdown
              children={questionData}
              remarkPlugins={[remarkGfm]}
              renderers={{
                code: CodeBlock,
              }}
            />
          </TabPane>
          <TabPane tab="Solution" key="2">
            <ReactMarkdown
              children={showSolution}
              remarkPlugins={[remarkGfm]}
              renderers={{
                code: CodeBlock,
              }}
            />
          </TabPane>
        </Tabs>
        </Paper>
  );
}

export default QuestionBox;
