import React, { Component } from "react";
import Axios from "axios";
//import * as Constants from "../Config/constants";

class Photon extends Component {
  static post = (ip, port, endpoint, obj) => {
    //post = () => (ip, port, endpoint, obj) => {
    return new Promise((resolve) => {
      try {
        console.log("got inside photo.post fnx");
        let url = ip + port + endpoint;
        console.log("sending post request to = " + url);

        let res = Axios.post(url, obj);
        resolve(res);
        //console.log(res);
      } catch (error) {
        console.log("error = " + error);
      }
    });
  };
}
export default Photon;
