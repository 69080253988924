import Cookies from "universal-cookie";
import Photon from "../Photon/photon";
import * as Constants from "../Config/constants";
const cookies = new Cookies();
class Auth {
  constructor() {
    this.authenticated = false;
    this.user_data = { name: "", email: "", picture: "" };
  }

  login(cb) {
    this.authenticated = true;

    cb();
  }

  set_user_data(user_data) {
    console.log("user_data inside auth === " + JSON.stringify(user_data));
    this.user_data.name = user_data.name;
    this.user_data.email = user_data.email;
    this.user_data.picture = user_data.picture;
    return;
  }

  get_user_data() {
    const resultObj = this.user_data;
    return resultObj;
  }

  logout(cb) {
    console.log("inside auth : logout");
    cookies.remove("tokenId");
    this.authenticated = false;
    cb();
  }

  async oneTime_FirstLogin(tokenid) {
    console.log("got inside oneTime_FirstLogin");
    const result = await this.verify_google_token(tokenid);
    console.log("result inside ontime login");
    console.log(result);
    return result;
  }

  async isAuthenticated() {
    if (this.authenticated) {
      console.log("inside this.isAuthenticated true");
      return this.authenticated;
    }
    console.log("check with cookie");
    const result = await this.server_google_authetication(null);
    //not wating here
    console.log("result inside protected route" + JSON.stringify(result));
    if (result) {
      console.log("result true : login : authenticated " + this.authenticated);
      this.login(() => {});
      return this.authenticated;
    } else {
      console.log("result false : authenticated " + this.authenticated);
      return this.authenticated;
    }
  }

  verify_google_token = async (token_id) => {
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint = "/auth_google";
    let obj = {
      token_id: token_id,
    };
    console.log("sending post reuqest to " + ip + port);

    let res = await Photon.post(ip, port, endpoint, obj);
    console.log("photon reponse " + JSON.stringify(res));
    return res;
  };

  check_token_inside_cookie = async () => {
    if (cookies.get("tokenId")) {
      console.log(cookies.get("tokenId"));
      let result = await this.verify_google_token(cookies.get("tokenId"));
      if (result.data.tokenIsVerified) {
        console.log("google verified from cookie" + result.data.response.name);
        const resultObj = {
          name: result.data.response.name,
          email: result.data.response.email,
          picture: result.data.response.picture,
        };
        this.set_user_data(resultObj);

        return true;
      } else {
        //return user to login page
        console.log("google cookie verification failed from cookie");
        //this.setState({ Navigate: "/" });
        return false;
      }
    } else {
      console.log("cookie not found");
      return false; //this.setState({ Navigate: "/" });
    }
  };

  server_google_authetication = async (tokenId) => {
    console.log(tokenId);
    if (!tokenId) {
      console.log("inside token not found");

      let result = await this.check_token_inside_cookie();
      console.log(result);
      return result;
    }
    // let result = await this.verify_google_token(tokenId);
    // if (result.data.tokenIsVerified) {
    //   console.log("google verified from props");
    //   this.store_token_inside_cookie();
    // } else {
    //   //send user to login
    //   //this.setState({ Navigate: "/" });
    // }
    // console.log(result);
  };
}

export default new Auth();
