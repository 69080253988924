import React, { useState, useEffect } from "react";
import auth from "../Auth/auth";
import Header from "../header";
import { ip, port } from "../Config/constants";
import Photon from "../Photon/photon";
import CreateTeam from "./create_team";
import JoinTeam from "./join_team";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Stack,
  Paper,
  Card,
  Tooltip,
  Divider,
} from "@mui/material";

function TeamsPage(props) {
  const [userData, setUserData] = useState({});
  const [teamId, setTeamId] = useState("");
  const [teamData, setTeamData] = useState({});
  const [teamFound, setTeamFound] = useState(null);
  const [value, setValue] = React.useState(1);

  const handleChange = (event, value) => {
    console.log(value);
    setValue(value);
  };

  const get_teamid_from_emailid = async () => {
    let endpoint = "/get_teamid_from_email";
    let requestObject = { email: userData.email };

    let responseObject = await Photon.post(
      ip,
      port.hive,
      endpoint,
      requestObject
    );

    setTeamId(responseObject.data.team_id);
  };

  const get_teamdata_from_teamid = async () => {
    let endpoint = "/get_teamdata_from_teamid";
    let requestObject = { teamid: teamId };

    let responseObject = await Photon.post(
      ip,
      port.hive,
      endpoint,
      requestObject
    );

    if (responseObject.data.status) {
      setTeamFound(true);
      setTeamData(responseObject.data.team_data);
    } else {
      setTeamFound(false);
    }
  };

  const callback = (key) => {
    console.log(key);
  };

  useEffect(() => {
    setUserData(auth.get_user_data());
  }, []);

  useEffect(() => {
    if (userData && userData.email) {
      get_teamid_from_emailid();
    }
  }, [userData]);

  useEffect(() => {
    if (teamId) {
      get_teamdata_from_teamid();
    }
  }, [teamId]);

  const CustomTabPanel = (props) => {
    return <Box hidden={props.value !== props.index}>{props.children}</Box>;
  };

  return (
    <div>
      <Header history={props.history} />

      <Stack justifyContent="center" sx={{ mt: 10, mr: 50, ml: 50 }}>
        <Paper elevation={3}>
          <Stack justifyContent="center" direction="row" sx={{ pt: 2, pb: 2 }}>
            <Typography variant="h4">My Team</Typography>
          </Stack>
          <Divider variant="middle" />
          <Stack justifyContent="center" sx={{ px: 15, pt: 2 }}>
            <Card>
              <Stack justifyContent="flex-start" direction="row">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Create Team" />
                  <Tab label="Join Team" />
                </Tabs>
              </Stack>
              <Stack
                justifyContent="flex-start"
                direction="row"
                sx={{ pt: 2, pb: 2 }}
                minHeight={200}
              >
                <CustomTabPanel value={value} index={0}>
                  <CreateTeam />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <JoinTeam />
                </CustomTabPanel>
              </Stack>
            </Card>
          </Stack>
          <Stack
            justifyContent="flex-start"
            direction="row"
            sx={{ pl: 15, pt: 2, pb: 2 }}
          >
            <Card variant="outlined" sx={{ minWidth: 475 }}>
              <Typography variant="h6" display="block">
                {teamFound ? "Team : " + teamData.team_name : ""}
              </Typography>
              <Typography variant="h6">
                {teamFound ? "Join Code: " + teamData.join_code + " " : ""}

                {teamFound ? (
                  <CopyToClipboard
                    text={teamData.join_code}
                    onCopy={() => console.log("copied!!")}
                  >
                    <Tooltip title="Click to Copy Join Code">
                      <CopyOutlined />
                    </Tooltip>
                  </CopyToClipboard>
                ) : (
                  "You have not joined any team yet"
                )}
              </Typography>
            </Card>
          </Stack>
        </Paper>
      </Stack>
    </div>
  );
}

export default TeamsPage;
