import React, { useEffect, useState } from "react";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import { Table, Tag, Alert } from "antd";
import { useNavigate } from "react-router-dom";

function ListQuestions(props) {

  const { user_data, history } = props
  const [questions, setQuestions] = useState([]);
  const [userData, setUserData] = useState({});
  const [teamId, setTeamId] = useState(undefined);
  const [dataSource, setDataSource] = useState([]);
  const [isUserPartOfAnyTeam, setIsUserPartOfAnyTeam] = useState(false);
  const [columns, setColumns] = useState([]);

  const navigate = useNavigate();

  const makeDataSource = () => {
    console.log("Inside make data source");
    const newDataSource = [];
    for (let i = 0; i < questions.length; i++) {
      console.log("hiiiii " + questions[i]);
      newDataSource.push({
        key: (i + 1).toString(),
        question_id: questions[i].question_id,
        question_name: questions[i].question_name,
        difficulty: questions[i].difficulty,
        companytag: questions[i].companytag,
      });
    }
    return newDataSource;
  };

  const callPage = (e) => {
    console.log(
      "change page teams called." +
        e.currentTarget.dataset.id +
        " " +
        e.currentTarget.dataset.name
    );
    const id = e.currentTarget.dataset.id;
    const name = e.currentTarget.dataset.name;
    navigate("/questions/" + id + "/" + name);
  };

  const populateTable = () => {
    let newDataSource = [];

    const newColumns = [
      {
        title: "ID",
        dataIndex: "question_id",
        key: "question_id",
      },
      {
        title: "Questions",
        dataIndex: "question_name",
        key: "question_name",
        render: (text, record) => (
          <a
            data-id={record.question_id}
            data-name={record.question_name}
            onClick={callPage}
          >
            {record.question_name}
          </a>
        ),
      },
      {
        title: "Diffuculty",
        dataIndex: "difficulty",
        key: "difficulty",
        render: (text) => {
          let color = "green";
          if (text === "easy") {
            color = "green";
          } else if (text === "medium") {
            color = "geekblue";
          } else if (text === "hard") {
            color = "volcano";
          }
          return (
            <Tag color={color} key={text}>
              {text}
            </Tag>
          );
        },
      },
      {
        title: "companytag",
        dataIndex: "companytag",
        key: "companytag",
        render: (tags) => (
          <>
            {tags.map((tag) => {
              let color = tag.length > 5 ? "geekblue" : "green";
              if (tag === "google") {
                color = "green";
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
    ];
    newDataSource = makeDataSource();
    setColumns(newColumns);
    setDataSource(newDataSource);
    console.log("data source");
    console.log(dataSource);
    console.log("columns");
    console.log(columns);
  };

const fetchQuestions = async () => {
  console.log("inside fetch fnxx " + teamId);
  let ip = Constants.ip;
  // let ip = "http://localhost:";
  let port = Constants.port.hive;
  let endpoint = "/get_questions_by_teamid";
  let obj = { teamid: teamId };
  console.log(JSON.stringify(obj));
  let res = await Photon.post(ip, port, endpoint, obj);
  console.log("after post request");
  setQuestions(res.data.questions);
};

const getTeamIdFromEmailId = async (email) => {
  let ip = Constants.ip;
  // let ip = "http://localhost:";
  let port = Constants.port.hive;
  let endpoint = "/get_teamid_from_email";
  let obj = { email: email };
  let res = await Photon.post(ip, port, endpoint, obj);
  console.log(JSON.stringify(res.data));
  setTeamId(res.data.team_id);
  
  // New user show alert to join/create team
  if (!res.data.status) {
    setIsUserPartOfAnyTeam(true);
  }
  return res.data.team_id;
};

useEffect(() => {
  if (!!teamId){
    fetchQuestions();
  }
 
}, [teamId]);

useEffect(() => {
  console.log(questions);
  populateTable();
}, [questions]);

useEffect(() => { 
  if (user_data && user_data.email) {
    setUserData(user_data);
    console.log('user data ====== ' + JSON.stringify(user_data));
    getTeamIdFromEmailId(user_data.email);
  }
}, [user_data]);

useEffect(() => {
  console.log("DATA SOURCE ===== ");
console.log(dataSource)
}, [dataSource]);


  return (
    <div>
      {isUserPartOfAnyTeam ? (
        <div className="alertnewuser">
          <Alert
            message="Welcome to CodeRunnr, You are not part of any team yet, 
            To start solving questions you will need 
            to join a team or create a team. Go to My Team tab and get started!
            When you join a team you will immediately see all the questions your teamates
            are solving, When you create a team we will add one question immediately to get 
            you started. We send one question everyday at 9AM EST after that."
            banner
            closable
            type="warning"
          />
        </div>
      ) : null}
      <div className="listquestions">
        <Table dataSource={dataSource} columns={columns} />
      </div>
    </div>
  );
}

export default ListQuestions;