import React from "react";

function Pricing() {
  return (
    <section id="pricing" className="feature-area gap">
      <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className="display-4">Pricing</h1>
        <p className="lead">
          After the free preview we plan to have two pricing options
        </p>
      </div>

      <div className="container">
        <div className="card-deck mb-3 text-center">
          {/* <div className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">Free</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                $0 <small className="text-muted">/ mo</small>
              </h1>
              <ul className="list-unstyled mt-3 mb-4">
                <li>1 coding question each day</li>
              </ul>
              <br />
              <br />
              <br />
              <br />
              <button
                type="button"
                className="btn btn-lg btn-block btn-outline-primary"
                disabled
              >
                Coming Soon
              </button>
            </div>
          </div> */}
          <div className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">Monthly</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                $25 <small className="text-muted"> </small>
              </h1>
              {/* <ul className="list-unstyled mt-3 mb-4">
                <li>You pay a $500 deposit</li>
                <li>and then you pay us nothing</li>
                <li>until you land a job paying minimum $70K.</li>
              </ul> */}
              {/* <br />
              <br /> */}
              <a
                target="_blank"
                className="btn btn-lg btn-block btn-primary"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdf8umDiPEmr5dsfv9FL7MSc3JSzx6s9HDQO_Kcso5185-95g/viewform?usp=sf_link"
                rel="noreferrer"
              >
                Get started
              </a>
            </div>
          </div>
          <div className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">Yearly</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title pricing-card-title">
                $99 <small className="text-muted"></small>
              </h1>
              {/* <ul className="list-unstyled mt-3 mb-4">
                <li>You pay $9000 upfront,</li>
                <li>and once we help you secure</li>
                <li>a job, there is no additional</li>
                <li>Income Share Agreement (I.S.A) </li>
                <li>to repay.</li>
              </ul> */}
              <a
                target="_blank"
                className="btn btn-lg btn-block btn-primary"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdf8umDiPEmr5dsfv9FL7MSc3JSzx6s9HDQO_Kcso5185-95g/viewform?usp=sf_link"
                rel="noreferrer"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
