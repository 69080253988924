import React, { Component } from "react";
import auth from "../Auth/auth";
import Header from "../header";
import ListQuestions from "./listquestions";

export class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenId: null,
      user_data: {},
    };
  }
  async componentDidMount() {
    console.log("component mounted : DashboardPage");
    const user_dataa = await auth.get_user_data();
    console.log("user_data inside dashboard=== " + JSON.stringify(user_dataa));
    this.setState({
      tokenId: this.state.tokenId,
      user_data: user_dataa,
    });
    console.log(
      "welcome : Email : " +
        this.state.user_data.email +
        " : Name : " +
        this.state.user_data.name
    );
  }
  render() {
    return (
      <div>
        <Header history={this.props.history} />

        <ListQuestions
          user_data={this.state.user_data}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default DashboardPage;
