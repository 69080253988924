import React, { Component } from "react";
import auth from "../Auth/auth";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-eclipse";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-java";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import base64 from "react-native-base64";
import { Button, Select, Collapse } from "antd";
// import "antd/dist/antd.css";

import { CaretRightOutlined } from "@ant-design/icons";
import { Tabs, Alert, Result, Tag } from "antd";

import Paper from "@mui/material/Paper";
import "../../css/style.css";

const { TabPane } = Tabs;

const { Panel } = Collapse;
const { Option } = Select;

export class EditorBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenId: null,
      userProgram: "",
      language: "python",
      editor_language: "python",
      autocomplete: true,
      output: {},
      collapse_output: "0",
      question_id: "",
      team_members: [],
      friendProgram: "",
    };
    this.call_to_runtest_function = this.call_to_runtest_function.bind(this);
    this.call_to_fecthCode = this.call_to_fecthCode.bind(this);
    this.callback = this.callback.bind(this);
  }
  async componentDidMount() {
    console.log("component mounted : EditorBox", this.props);
    const user_dataa = await auth.get_user_data();
    console.log("user_data inside chatbox=== " + JSON.stringify(user_dataa));
    await this.setState({
      tokenId: this.state.tokenId,
      user_data: user_dataa,
    });
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint = "/get_team_by_id";
    let obj = { email: this.state.user_data.email };
    console.log("obj");
    const team_members = await Photon.post(ip, port, endpoint, obj);
    let team_members_data = team_members.data.team_members_data;
    team_members_data = await this.remove_self_user(team_members_data);
    await this.setState({ team_members: team_members_data });
  }

  async componentWillReceiveProps(newProps) {
    console.log(
      "This is id question inside editorbox component== " +
        JSON.stringify(newProps.question_data)
    );
    await this.setState({ question_id: newProps.question_data.id });
    this.call_to_fecthCode();
  }

  remove_self_user = (team_members_data) => {
    let team = [];
    for (let i = 0; i < team_members_data.length; i++) {
      if (team_members_data[i].email != this.state.user_data.email) {
        team.push(team_members_data[i]);
      }
    }
    console.log("team without self" + JSON.stringify(team));
    return team;
  };

  selectLanguage = (value) => {
    console.log(value);
    console.log("lll", value);
    if (value == "cpp") {
      this.setState({ editor_language: "c_cpp" });
    } else {
      this.setState({ editor_language: value });
    }
    this.setState(
      {
        language: value,
      },
      () => {
        this.call_to_fecthCode();
        console.log("fetch code called");
      }
    );
  };

  call_to_fecthCode = async () => {
    let obj = {
      user_id: this.state.user_data.email,
      code_language: this.state.language,
      question_id: this.state.question_id,
    };
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint = "/fetchCode";

    let res = await Photon.post(ip, port, endpoint, obj);
    //console.log(JSON.stringify(res));
    let decodedProgram;
    if ("code" in res.data) {
      decodedProgram = base64.decode(res.data.code);
    } else {
      //move to dashboard
      this.props.history.push("/dashboard");
    }
    //let decodedProgram = base64.decode(res.data.code);
    this.setState(
      {
        userProgram: decodedProgram,
      },
      () => {
        //console.log("here" + this.state.userProgram);
      }
    );
  };

  call_to_submit_function = async () => {
    let encodedProgram = base64.encode(this.state.userProgram);
    let ip = Constants.ip;
    let port = Constants.port.hive;
    let endpoint = "/submit_code";
    let obj = {
      user_id: this.state.user_data.email,
      code: encodedProgram,
      code_language: this.state.language,
      question_id: this.state.question_id,
      type: "submit",
    };
    let res = await Photon.post(ip, port, endpoint, obj);
    console.log("OUTPUT = " + JSON.stringify(res));
    this.setState(
      {
        output: res.data.output,
        collapse_output: "1",
      },
      () => {
        console.log("here" + JSON.stringify(this.state.output));
      }
    );
  };

  call_to_runtest_function = async () => {
    console.log("inside run test");
    //console.log(this.state.userProgram);
    console.log(this.state.language);
    let code_language = this.state.language;

    let encodedProgram = base64.encode(this.state.userProgram);
    //console.log(encodedProgram);
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint = "/submit_code";
    let obj = {
      user_id: this.state.user_data.email,
      code: encodedProgram,
      code_language: code_language,
      question_id: this.state.question_id,
      type: "run",
    };
    let res = await Photon.post(ip, port, endpoint, obj);
    console.log(JSON.stringify(res));
    this.setState(
      {
        output: res.data.output,
        collapse_output: "1",
      },
      () => {
        console.log("here" + this.state.output);
      }
    );
    //this.state.output = res.data.output;
    //let res = res1.json();
  };

  onChange = (newValue) => {
    this.state.userProgram = newValue;
    console.log("change", newValue);
  };

  onChange_collapse = () => {
    this.state.collapse_output === "0"
      ? this.setState({ collapse_output: "1" })
      : this.setState({ collapse_output: "0" });
  };

  async callback(key) {
    if (key != 1) {
      console.log(key);
      let obj = {
        user_id: key,
        code_language: this.state.language,
        question_id: this.state.question_id,
      };
      let ip = Constants.ip;
      //let ip = "http://localhost:";
      let port = Constants.port.hive;
      let endpoint = "/fetchCode";

      let res = await Photon.post(ip, port, endpoint, obj);
      //console.log(JSON.stringify(res));
      let decodedProgram = base64.decode(res.data.code);
      this.setState(
        {
          friendProgram: decodedProgram,
        },
        () => {
          //console.log("here" + this.state.userProgram);
        }
      );
    }
  }

  render() {
    return (
      // <div className="EditorBoxContainer">
      //   <div className="EditorBoxCss">
      <Paper elevation={3} sx={{p: 2  , width: "53%"}}>

      {/* <h1>Editor Box</h1> */}
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="Me" key="1">
              <AceEditor
                value={this.state.userProgram}
                mode={this.state.editor_language}
                theme="chrome"
                onChange={this.onChange}
                enableLiveAutocompletion={this.state.autocomplete}
                width="100%"
              />
              <Select
                defaultValue={this.state.language}
                onChange={this.selectLanguage}
              >
                <Option value="cpp">C/C++</Option>
                <Option value="python">Python</Option>
                <Option value="java">Java</Option>
              </Select>
              <Button
                className="run-button"
                type="primary"
                onClick={this.call_to_runtest_function}
                ghost
              >
                Run Code
              </Button>
              <Button
                className="submit-button"
                type="primary"
                onClick={this.call_to_submit_function}
              >
                Submit
              </Button>
              <Collapse
                bordered={false}
                activeKey={this.state.collapse_output}
                onChange={this.onChange_collapse}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
              >
                <Panel
                  header="Code Output"
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  {this.state.output.status == "accepted" ? (
                    <div className="accepted">
                      <Result
                        status="success"
                        title="Accepted"
                        subTitle={this.state.output.stdout}
                      />
                    </div>
                  ) : null}

                  {this.state.output.status == "failed" ? (
                    <div className="failed">
                      <Alert
                        message="Test Case Failed"
                        description=""
                        type="warning"
                        showIcon
                      />
                      <div className="testcasefailedbody">
                        <p>
                          Your Input:{" "}
                          <Tag color="blue">{this.state.output.test_case}</Tag>
                        </p>
                        <p>
                          Expected Output:{" "}
                          <Tag color="blue">
                            {this.state.output.expected_out}
                          </Tag>
                        </p>

                        <p>
                          STDOUT:
                          <Tag color="blue">{this.state.output.stdout}</Tag>
                        </p>

                        <p>
                          Your Output:{" "}
                          <Tag color="blue">
                            {this.state.output.your_output}
                          </Tag>
                        </p>
                        <p>
                          Test Cases Passed:{" "}
                          <Tag color="blue">{this.state.output.passed}</Tag>
                        </p>
                        <p>
                          Total Test Cases:{" "}
                          <Tag color="blue">{this.state.output.total}</Tag>
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {this.state.output.status == "error" ? (
                    <div className="error">
                      <Alert
                        message="Run Time Error"
                        description=""
                        type="error"
                        showIcon
                      />
                      <p className="display-linebreak">
                        {this.state.output.stderrout}
                      </p>
                    </div>
                  ) : null}
                </Panel>
              </Collapse>
            </TabPane>

            {this.state.team_members.map((item, i) => {
              return (
                <TabPane tab={item.name} key={item.email}>
                  <AceEditor
                    value={this.state.friendProgram}
                    mode={this.state.editor_language}
                    theme="chrome"
                    onChange={this.onChange}
                    enableLiveAutocompletion={this.state.autocomplete}
                    readOnly={true}
                  />
                </TabPane>
              );
            })}
          </Tabs>
        </Paper>
    );
  }
}

export default EditorBox;
