import React, { Component } from "react";
import auth from "../Auth/auth";
import io from "socket.io-client";
import { Input } from "antd";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import chat_notification from "../../static/chat_notification.mp3";

import Paper from "@mui/material/Paper"


let ip = Constants.ip;
//let ip = "http://localhost:";
let port = Constants.port.hive;
console.log("socket connection to " + Constants.port.hermes);
const socket = io(ip + Constants.port.hermes);
//const socket = io("http://localhost:3001");

export class ChatBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenId: null,
      input: "",
      team_id: "",
      question_id: "",
      user_data: {},
      messages: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }

  async componentDidMount() {
    console.log("component mounted : ChatBox", this.props);
  }

  async componentWillReceiveProps(newProps) {
    console.log(
      "This is id question inside editorbox component== " +
        JSON.stringify(newProps.question_data)
    );
    await this.setState({ question_id: newProps.question_data.id });
    this.chatbox_helper();
  }

  async chatbox_helper() {
    //set user data
    const user_dataa = await auth.get_user_data();
    console.log("user_data inside chatbox=== " + JSON.stringify(user_dataa));
    this.setState({
      tokenId: this.state.tokenId,
      user_data: user_dataa,
    });

    //join room
    const username = this.state.user_data.email;
    //get team id from server
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint = "/get_teamid_from_email";
    let obj = { email: username };
    let res = await Photon.post(ip, port, endpoint, obj);
    console.log("team id from server == ");
    console.log(res.data.team_id);
    await this.setState({ team_id: res.data.team_id });
    console.log(this.state.team_id);
    const room = this.state.team_id + "_" + this.state.question_id;
    console.log(room);
    socket.emit("joinRoom", { username, room });

    // Get room and users
    socket.on("roomUsers", ({ room, users }) => {
      console.log("room = " + room + "== user == " + users);
    });

    // Message from server
    socket.on("message", (message) => {
      this.setState({ messages: [...this.state.messages, message] });
      console.log(this.state.messages);
      console.log(message);
      //Play chat notification sound only when message is not from self
      if (
        message.user != this.state.user_data.email &&
        message.status === "new"
      ) {
        new Audio(chat_notification).play(
          () => {
            console.log("playing");
          },
          (errorCode, description) => {
            // maybe failure happened _during_ playback, maybe it failed to start.
            // depends on what is passed to the function.
            // errorCode is currently based on W3 specs for HTML5 playback failures.
            // https://html.spec.whatwg.org/multipage/embedded-content.html#error-codes
          }
        );
      }
      // Scroll down
    });
  }

  handleChange = (event) => {
    this.setState({ input: event.target.value });
    console.log("change", this.state.input);
  };

  handleSubmit = () => {
    console.log(this.state.input);
    socket.emit("chatMessage", this.state.input);
    this.setState({ input: "" });
  };

  keyPress(e) {
    if (e.keyCode == 13) {
      console.log("value", e.target.value);
      // put the login here
      this.handleSubmit();
    }
  }

  render() {
    return (
      // <div className="chatBoxContainer">
      //   <div className="ChatBoxCss">
      <Paper elevation={3} sx={{p: 2  , width: "23%"}}>

          <div className="ChatBoxDisplayCss">
            {this.state.messages.reverse().map((item, i) => {
              return (
                <p key={i}>
                  <b style={{ color: "blue" }}>{item["user"]}</b>
                  <br />
                  {item["text"]}
                </p>
              );
            })}
          </div>
          <div className="ChatBoxInputCss">
            <Input
              type="text"
              placeholder="Message..."
              value={this.state.input}
              onChange={this.handleChange}
              onKeyDown={this.keyPress}
            />
          </div>
     </Paper>
    );
  }
}

export default ChatBox;
