import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import auth from './Auth/auth';

export const ProtectedRoute = () => {
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const checkToken = async () => {
      console.log("inside checkToken");
      const token = await auth.isAuthenticated();
      console.log("token ===== " + token);
      setAuthenticated(token);
    };

    checkToken();
  }, []);

  // Render conditionally based on authentication status
  if (authenticated === null) {
    // Waiting for authentication status, you can render a loading indicator here
    return <div>Loading...</div>;
  } else if (authenticated) {
    // If authorized, return an outlet that will render child elements
    return <Outlet />;
  } else {
    // If not authorized, return an element that will navigate to the login page
    return <Navigate to="/" />;
  }
};



// import React, { Component } from "react";
// import { Route, Navigate } from "react-router-dom";
// import auth from "./Auth/auth";

// export class ProtectedRoute extends Component {
//   constructor(props, context) {
//     super(props, context);

//     this.state = {
//       isLoading: true,
//       isLoggedIn: false,
//     };
//   }

//   async componentDidMount() {
//     let r = await auth.isAuthenticated();
//     if (r) {
//       console.log("r = " + r);
//       this.setState(() => ({ isLoading: false, isLoggedIn: true }));
//     } else {
//       console.log("r is false = " + r);
//       this.setState(() => ({ isLoading: false, isLoggedIn: false }));
//     }
//   }

//   render() {
//     return this.state.isLoading ? null : this.state.isLoggedIn ? (
//       <Route
//         path={this.props.path}
//         component={this.props.component}
//         exact={this.props.exact}
//       />
//     ) : (
//       <Navigate to={{ pathname: "/", state: { from: this.props.location } }} />
//     );
//   }
// }a

// export default ProtectedRoute;
