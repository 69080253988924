import React, { useState, useEffect } from "react";
import auth from "../Auth/auth";
import Cookies from "universal-cookie";
import Header from "../header";
import QuestionBox from "./question_box";
import EditorBox from "./editor_box";
import ChatBox from "./chat_box";
import "../../css/style.css";
import { useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const cookies = new Cookies();

function QuestionPage(props) {
  const [tokenId, setTokenId] = useState(null);
  const [questionData, setQuestionData] = useState({});

  const { id, name } = useParams();

  useEffect(() => {
    console.log("component mounted : QuestionsPage", props);
    
    const questionObj = {
      id: id,
      name: name,
      detail: "",
    };

    setQuestionData(questionObj);
    console.log(questionData);
  }, []);

  return (
    <Box>
      <Header history={props.history} />
      <Stack direction="row" 
             justifyContent={'space-between'}
             sx={{p: 1}}
             > 
        <QuestionBox question_data={questionData} history={props.history} />
        <EditorBox question_data={questionData} history={props.history} />
        <ChatBox question_data={questionData} />
      </Stack>
    </Box>
  );
}

export default QuestionPage;
