import React, { Component } from "react";
import auth from "../Auth/auth";
import * as Constants from "../Config/constants";
import Photon from "../Photon/photon";
import { Button, TextField, Typography, Stack, Box } from "@mui/material";

export class JoinTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenId: null,
      user_data: {},
      join_code: "",
      response: "",
    };
  }
  async componentDidMount() {
    console.log("component mounted : join team component");
    const user_dataa = await auth.get_user_data();
    console.log(
      "user_data inside join team sub component=== " +
        JSON.stringify(user_dataa)
    );
    this.setState({
      tokenId: this.state.tokenId,
      user_data: user_dataa,
    });
    console.log(
      "welcome : Email : " +
        this.state.user_data.email +
        " : Name : " +
        this.state.user_data.name
    );
  }

  handleChange = (event) => {
    this.setState({ join_code: event.target.value });
    console.log("change", this.state.join_code);
  };

  handleSubmit = async () => {
    console.log(this.state.join_code);
    console.log(this.state.user_data.email);
    let obj = {
      email: this.state.user_data.email,
      join_code: this.state.join_code,
    };
    let ip = Constants.ip;
    //let ip = "http://localhost:";
    let port = Constants.port.hive;
    let endpoint = "/join_team_with_joincode";
    let res = await Photon.post(ip, port, endpoint, obj);
    console.log(JSON.stringify(res));
    this.setState({ response: res.data });
    this.setState({ join_code: "" });
  };

  keyPress(e) {
    if (e.keyCode == 13) {
      console.log("value", e.target.value);
      // put the login here
      //this.handleSubmit();
    }
  }
  render() {
    return (
      <div>
        <Stack>
          <Stack width={300}>
            <Typography variant="h5" sx={{ py: 1, mx: 2 }}>
              Join Team
            </Typography>
            <TextField
              label="Enter Join Code"
              variant="outlined"
              value={this.state.join_code}
              onChange={this.handleChange}
              onKeyDown={this.keyPress}
              sx={{ py: 1, mx: 2 }}
            />

            <Button
              variant="contained"
              onClick={this.handleSubmit}
              sx={{ py: 1, mx: 2 }}
            >
              Join Team
            </Button>
          </Stack>
          <Stack>{this.state.response.message}</Stack>
        </Stack>
      </div>
    );
  }
}

export default JoinTeam;
